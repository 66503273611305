import { useEffect, useState } from "react";
import ModalPayments from "./ModalPayments";
import { Card } from "reactstrap";
import socialNetworkImage from "@/assets/images/socialNetwork.png";
import check from "@/assets/images/check.png";

const ProductCards = ({ e, pillTab, currentMsisdn }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const [planDetails, setPlanDetails] = useState(null);

  useEffect(() => {
    const parsedData = parseHtmlData(e.description);
    setPlanDetails(parsedData);
  }, []);

  const decodeHtmlEntities = (str) => {
    const entityMap = {
      "&nbsp;": " ",
      "&amp;": "&",
      "&lt;": "<",
      "&gt;": ">",
      "&quot;": '"',
      "&#39;": "'",
    };

    return str.replace(
      /&[a-zA-Z0-9#]+;/g,
      (entity) => entityMap[entity] || entity
    );
  };

  const stripHtmlAndDecode = (html) => {
    return decodeHtmlEntities(html.replace(/<\/?[^>]+(>|$)/g, "").trim());
  };

  const parseHtmlData = (html) => {
    const tiempoMatch = html.match(/tiempo: (.*?)<\/p>/)?.[1] || "";
    const gbMatch = html.match(/gb:(.*?)<\/p>/)?.[1] || "";
    const rsMatch = html.match(/rs:(.*?)<\/p>/)?.[1] || "";
    const minMatch = html.match(/min:(.*?)<\/p>/)?.[1] || "";
    const comMatch = html.match(/com:(.*?)<\/p>/)?.[1] || "";
    const tycMatch = html.match(/tyc:(.*?)<\/p>/)?.[1] || "";

    return {
      planDuration: stripHtmlAndDecode(tiempoMatch),
      gb: stripHtmlAndDecode(gbMatch),
      rs: stripHtmlAndDecode(rsMatch),
      min: stripHtmlAndDecode(minMatch),
      com: stripHtmlAndDecode(comMatch),
      tyc: tycMatch.trim(),
    };
  };

  return (
    <Card
      className="text-center cursor-pointer"
      style={{ width: "330px", borderRadius: "30px" }}
    >
      <ModalPayments
        isOpen={isOpen}
        onToggle={handleOpen}
        e={e}
        currentMsisdn={currentMsisdn}
      />
      <div
        style={{
          flex: 1,
          background: "linear-gradient(-60deg, #000000 50%, #d0d0d0 100%)",
          borderRadius: "30px",
          padding: "16px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h2 style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}>
            {e.name.split(pillTab.value)[1]}
          </h2>
          {planDetails && (
            <div style={{ textAlign: "center", paddingTop: "8px" }}>
              <p
                style={{ fontSize: "28px", fontWeight: "bold", color: "white" }}
              >
                {planDetails.gb}
              </p>
              <p
                style={{
                  fontSize: "40px",
                  fontWeight: "bold",
                  color: "#81d3f6",
                }}
              >
                ${e.amount}
              </p>
              <p style={{ fontSize: "20px", color: "white" }}>
                {planDetails.planDuration}
              </p>
              <p
                style={{ fontSize: "28px", fontWeight: "bold", color: "white" }}
              >
                {planDetails.gb}
              </p>
              <p style={{ fontSize: "20px", color: "white" }}>Para navegar *</p>
              <div
                style={{ position: "relative", width: "100%", height: "60px" }}
              >
                <img
                  src={socialNetworkImage} // Ensure this path is correct
                  alt="social"
                  style={{
                    position: "absolute",
                    right: "-10px",
                    top: "50%",
                    transform: "translateY(-15px)",
                    maxWidth: "100%", // Allow image to scale based on the container width
                    height: "auto", // Maintain aspect ratio
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "24px",
                }}
              >
                <img
                  src={check}
                  alt="check"
                  style={{ width: "25px", height: "25px", marginRight: "8px" }}
                />
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {planDetails.min}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "8px 0 16px",
                  width: "100%",
                }}
              >
                <button
                  onClick={handleOpen}
                  style={{
                    backgroundColor: "#81d3f6",
                    padding: "10px",
                    width: "120px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  ¡Lo quiero!
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ProductCards;
