import PropTypes from "prop-types";
import React from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Spinner,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../slices/thunks";

// import images
// import profile from "@images/bg.png";
import logoLight from "@images/logo-dark.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { createSelector } from "reselect";
import { errorAlert, successAlert } from "../../helpers/alerts";
import { useState } from "react";
import { sendForgotPasswordReq } from '../../helpers/fakebackend_helper';
import { useNavigate } from "react-router-dom";

const ForgetPasswordPage = (props) => {
  document.title = "Reset Password | Infynit";

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const forgotPasswordReq = async (payload) => {
    setIsLoading(true);
    const formvalues = {
      user: payload.user,
    };
    try {
      setIsLoading(true);
      let response = await sendForgotPasswordReq(formvalues);

      if (response && response.status) {
        successAlert("Te enviamos un email con instrucciones para cambiar tu contraseña", response.result)
          .then(() => navigate('/login'));
        setIsLoading(false);
        return true;
      }

      setIsLoading(false);
      return false;
    } catch (err) {
      setIsLoading(false);
      errorAlert("Error", "Usuario no encontrado");
      return false;
    }
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      user: "",
    },
    validationSchema: Yup.object({
      user: Yup.string().required("Por favor ingrese su correo/número"),
    }),
    onSubmit: (values) => {
      forgotPasswordReq(values);
    },
  });

  const selectLayoutState = (state) => state.ForgetPassword;
  const selectLayoutProperties = createSelector(selectLayoutState, (state) => ({
    forgetError: state.forgetError,
    forgetSuccessMsg: state.forgetSuccessMsg,
  }));
  // Inside your component
  const { forgetError, forgetSuccessMsg } = useSelector(selectLayoutProperties);

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="100" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">¿Olvidaste tu contraseña?</h5>

                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#0ab39c"
                      className="avatar-xl"
                      style={{ width: "120px", height: "120px" }}
                    ></lord-icon>
                  </div>

                  <Alert
                    className="border-0 alert-warning text-center mb-2 mx-2"
                    role="alert"
                  >
                    Escribe aqui tu número o correo para recuperar tu contraseña!
                  </Alert>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">Número o Correo</Label>
                        <Input
                          name="user"
                          className="form-control"
                          placeholder="Ingresa número o correo"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.user || ""}
                          invalid={validation.touched.user && validation.errors.user}
                        />
                        {validation.touched.user && validation.errors.user &&
                          <FormFeedback type="invalid">
                            {validation.errors.user}
                          </FormFeedback>
                        }
                      </div>

                      <div className="text-center mt-4">
                        <button className="btn btn-success w-100" type="submit" disabled={isLoading}>
                          {!isLoading && <span>Restablecer Contraseña</span>}
                          {isLoading && <Spinner size="sm"></Spinner>}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p className="mb-0">
                  Espera, recordé mi contraseña...{" "}
                  <Link
                    to="/login"
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    Da clic aquí
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
