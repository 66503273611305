import React, { useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";

// import Cleave from "cleave.js/react";
// import "cleave.js/dist/addons/cleave-phone.in";
import classnames from "classnames";

import CheckImeiCard from "@Components/UI/CheckImeiCard";
import TitularUserForm from "./Components/TitularUserForm";
import SearchLocationButton from "./Components/SearchLocationButton";
import PhonePlanOffersCard from "./Components/PhonePlanOffersCard";

import { lineaNuevaList, hbbList, mifiList } from "./dummy/activationOffers";

const Activation = () => {
  document.title = "Activación | Infynit";

  const tabsList = [
    { title: "Linea nueva", text: "Linea nueva", value: "1" },
    {
      title: "Linea nueva Internet Hogar (HBB)",
      text: "HBB - Internet Hogar",
      value: "2",
    },
    {
      title: "Linea nueva Internet Movil (MIFI)",
      text: "MIFI - Internet Móvil",
      value: "3",
    },
  ];

  const [pillTab, setPillTab] = useState(tabsList[0]);
  const [isShowingImei, setIsShowingImei] = useState(true);

  const togglePillTab = (tab) => {
    if (pillTab.value !== tab.value) {
      setPillTab(tab);
      setIsShowingImei(true);
    }
  };

  const returnToTabOne = () => {
    setPillTab(tabsList[0]);
    setIsShowingImei(true);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h1>{pillTab.title}</h1>
          <Card>
            <CardBody>
              <Nav pills className="nav-justified align-items-center">
                {tabsList.map((tab) => (
                  <NavItem key={tab.value}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: pillTab.value === tab.value,
                      })}
                      onClick={() => {
                        togglePillTab(tab);
                      }}
                    >
                      {tab.text}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </CardBody>
          </Card>
          <TabContent activeTab={pillTab.value} className="text-muted">
            <TabPane tabId="1">
              {isShowingImei ? (
                <Row className="mx-auto">
                  <Col xs={12} sm={8} md={6}>
                    <CheckImeiCard onOmit={() => setIsShowingImei(false)} />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={12} sm={6}>
                    <TitularUserForm onReturn={returnToTabOne} />
                  </Col>
                  <Col xs={12} sm={5} className="text-center mx-auto">
                    <PhonePlanOffersCard offers={lineaNuevaList} />
                  </Col>
                </Row>
              )}
            </TabPane>

            <TabPane tabId="2">
              <Row>
                <Col xs={12} sm={8} md={6}>
                  <TitularUserForm
                    children={<SearchLocationButton />}
                    onReturn={returnToTabOne}
                  />
                </Col>
                <Col xs={12} sm={5} className="text-center mx-auto">
                  <PhonePlanOffersCard offers={hbbList} />
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="3">
              <Row>
                <Col xs={12} sm={6}>
                  <TitularUserForm onReturn={returnToTabOne} />
                </Col>
                <Col xs={12} sm={5} className="text-center mx-auto">
                  <PhonePlanOffersCard offers={mifiList} />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Activation;
