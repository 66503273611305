import React from "react";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import { ProductsProvider } from "../../../Components/Hooks/ProductsProvider";
import { Formik } from 'formik';
import * as Yup from "yup";
import Swal from 'sweetalert2';
import { errorAlert, successAlert } from '../../../helpers/alerts';

const ModalFactura = ({ isOpen, onToggle, paymentMethods, regimes, orderId }) => {
  const { useGenerateInvoiceQuery } = ProductsProvider();
  const { mutateAsync: generateInvoice } = useGenerateInvoiceQuery;

  const initialValues = {
    orderId,
    method: '',
    usage: '',
  };
  const validationSchema = Yup.object({
    orderId: Yup.string().required(),
    method: Yup.string().required(),
    usage: Yup.string().required(),
  });
  const handleSubmit = (values) => {
    Swal.fire({
      title: 'Confirmar generación de factura',
      confirmButtonText: 'Generar factura',
      confirmButtonColor: "primary",
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      icon: 'warning',
      preConfirm: async () => {
        try {
          await generateInvoice(values);
          onToggle();
          return successAlert('Factura generada');
        } catch (error) {
          return errorAlert(error.message);
        }
      }
    })
  };

  return (
    <div>
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          toggle={() => onToggle() }
          centered
          className="mx-auto"
          style={{ maxWidth: "700px" }}
        >
          <ModalBody>
            <Formik enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              { props => (<>
                <form onSubmit={props.handleSubmit}>
                  <Row>
                    <Col sm={12} md={6}>
                      <Label className="text-primary">Forma de pago</Label>
                      <select name="method" className="form-select"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.method || ''}
                      >
                        <option value="">Selecciona forma de pago</option>
                        { paymentMethods && paymentMethods.map(method =>
                          <option key={method.value} value={method.value}>{method.label}</option>
                        ) }
                      </select>
                    </Col>
                    <Col sm={12} md={6}>
                      <Label className="text-primary">Uso</Label>
                      <select name="usage" className="form-select"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.usage || ''}
                      >
                        <option value="">Selecciona uso</option>
                        { regimes && regimes.use && regimes.use.map(regime =>
                          <option key={regime.value} value={regime.value}>{regime.label}</option>
                        ) }
                      </select>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6} className="d-flex justify-content-center w-100">
                      <Button
                        disabled={false}
                        color="primary"
                        type="submit"
                        className="my-4"
                        style={{ minWidth: '15rem' }}
                      >
                        Generar factura
                      </Button>
                    </Col>
                  </Row>
                </form>
              </>)}
            </Formik>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </div>
  );
};

export default ModalFactura;
