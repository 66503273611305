import React, { useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";

import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.in";
import classnames from "classnames";

import portabilityImg from "./images/portability.png";
import cancelPortabilityImg from "./images/cancel-portability.png";
import statusImg from "./images/status.png";
import CheckImeiCard from "@Components/UI/CheckImeiCard";
import PortabilityProcessForm from "./Components/PortabilityProcessForm";

const Portability = () => {
  document.title = "Portabilidad | Infynit";

  const tabsList = [
    { title: "Portabilidad", text: "Portabilidad", value: "1" },
    { title: "Estatus de la portabilidad", text: "Estatus", value: "2" },
    { title: "Cancelar Portabilidad", text: "Cancelar proceso", value: "3" },
  ];

  const [pillTab, setPillTab] = useState(tabsList[0]);
  const [isShowingImei, setIsShowingImei] = useState(true);

  const togglePillTab = (tab) => {
    if (pillTab.value !== tab.value) {
      setPillTab(tab);
      setIsShowingImei(true);
    }
  };

  const returnToTabOne = () => {
    setPillTab(tabsList[0]);
    setIsShowingImei(true);
  };

  const [phone, setPhone] = useState(null);
  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h1>{pillTab.title}</h1>
          <Card>
            <CardBody>
              <Nav pills className="nav-justified mb-3 align-items-center">
                {tabsList.map((tab) => (
                  <NavItem key={tab.value}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: pillTab.value === tab.value,
                      })}
                      onClick={() => {
                        togglePillTab(tab);
                      }}
                    >
                      {tab.text}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={pillTab.value} className="text-muted">
                <TabPane tabId="1">
                  {isShowingImei ? (
                    <Row>
                      <Col xs={12} sm={8} md={6}>
                        <CheckImeiCard onOmit={() => setIsShowingImei(false)} />
                      </Col>
                      <Col xs={12} sm={4} className="text-center p-4 mx-auto">
                        <img
                          src={portabilityImg}
                          style={{ width: "100%", maxWidth: "250px" }}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col
                        xs={12}
                        sm={8}
                        md={6}
                        className="text-center mx-auto"
                      >
                        <PortabilityProcessForm onReturn={returnToTabOne} />
                      </Col>
                    </Row>
                  )}
                </TabPane>

                <TabPane tabId="2">
                  <Row>
                    <Col xs={12} sm={8} md={6}>
                      <Card className="h-100 border card-border-primary p-4">
                        <h4>Revisa el estado de tu cambio de compañía</h4>
                        Anote tu número de teléfono para consultar tu estado
                        actual
                        <CardBody>
                          <label htmlFor="cleave-phone" className="form-label">
                            Tu número de teléfono actual
                          </label>
                          <Cleave
                            placeholder="(xxx)-xxx-xxxx"
                            options={{
                              numericOnly: true,
                              blocks: [0, 3, 0, 3, 4],
                              delimiters: ["(", ")", "-"],
                              delimiterLazyShow: true,
                            }}
                            value={phone}
                            onChange={onPhoneChange}
                            className="form-control fs-1-25"
                          />
                          <button
                            className="btn btn-primary mt-4"
                            style={{ width: "100%" }}
                          >
                            Continuar
                          </button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs={12} sm={4} className="text-center p-4 mx-auto">
                      <img
                        src={statusImg}
                        style={{ width: "100%", maxWidth: "250px" }}
                      />
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="3">
                  <Row>
                    <Col xs={12} sm={6}>
                      <Card className="h-100 border card-border-primary p-4">
                        <h4>Cancelar proceso</h4>
                        Puedes cancelar el proceso enviando tu número de
                        teléfono.
                        <CardBody>
                          <label htmlFor="cleave-phone" className="form-label">
                            Número de teléfono
                          </label>
                          <Cleave
                            placeholder="(xxx)-xxx-xxxx"
                            options={{
                              numericOnly: true,
                              blocks: [0, 3, 0, 3, 4],
                              delimiters: ["(", ")", "-"],
                              delimiterLazyShow: true,
                            }}
                            value={phone}
                            onChange={onPhoneChange}
                            className="form-control fs-1-25"
                          />
                          <button
                            className="btn btn-primary mt-4"
                            style={{ width: "100%" }}
                          >
                            Continuar
                          </button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs={12} sm={4} className="text-center p-4 mx-auto">
                      <img
                        src={cancelPortabilityImg}
                        style={{ width: "100%", maxWidth: "250px" }}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Portability;
