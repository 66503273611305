import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
} from "reactstrap";

import "cleave.js/dist/addons/cleave-phone.in";
import classnames from "classnames";
import CatalogueTab from "./Components/CatalogueTab";
import PurchasesTab from "./Components/PurchasesTab";
import AddressesTab from "./Components/AddressesTab";
import AddAddressForm from "./Components/AddAddressForm";

const Marketplace = () => {
  document.title = "Marketplace | Infynit";

  const marketPlaceRoutes = {
    productos: { title: "Catálogo de productos", text: "Catálogo", value: "1" },
    "mis-compras": { title: "Mis compras", text: "Mis Compras", value: "2" },
    "mis-direcciones": {
      title: "Direcciones de envío",
      text: "Mis Direcciones",
      value: "3",
    },
  };

  const params = useParams();
  const { tab } = params;

  const tabsList = Object.entries(marketPlaceRoutes).map(([key, value]) => ({
    key,
    ...value,
  }));

  const [pillTab, setPillTab] = useState(tabsList[0]);
  const [isNewAddress, setNewAddress] = useState(false);

  const replaceRoute = (tabPath) => {
    let fullPath = "/marketplace";
    if (tabPath) fullPath = [fullPath, tabPath].join("/");
    window.history.replaceState(null, null, fullPath);
  };

  const togglePillTab = (selectedTab) => {
    if (pillTab.value !== selectedTab.value) {
      setPillTab(selectedTab);
      replaceRoute(selectedTab.key);
    }
  };

  useEffect(() => {
    replaceRoute(tab);
    if (marketPlaceRoutes[tab]) {
      setPillTab(marketPlaceRoutes[tab]);
    }
  }, [params]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h1>Marketplace - {pillTab.title}</h1>
          <Card>
            <CardBody>
              <Nav pills className="nav-justified align-items-center">
                {tabsList.map((tab) => (
                  <NavItem key={tab.value}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: pillTab.value === tab.value,
                      })}
                      onClick={() => {
                        togglePillTab(tab);
                      }}
                    >
                      {tab.text}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </CardBody>
          </Card>
          <TabContent activeTab={pillTab.value} className="text-muted">
            <TabPane tabId="1">
              <CatalogueTab />
            </TabPane>

            <TabPane tabId="2">
              <PurchasesTab />
            </TabPane>

            <TabPane tabId="3">
              {isNewAddress ? (
                <Row className="justify-content-center">
                  <Col xs={12} sm={8} md={6}>
                    <AddAddressForm onReturn={() => setNewAddress(false)} />
                  </Col>
                </Row>
              ) : (
                <AddressesTab onAddAddress={() => setNewAddress(true)} />
              )}
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Marketplace;
