import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";

const ProductsInventory = () => {
  document.title = "Inventario de Productos | Infynit";

  const headers = [
    { text: "Remitente", value: "remitente" },
    { text: "Destinatario", value: "destinatario" },
    { text: "Tipo de traspaso", value: "tipo" },
    { text: "Fecha de traspaso", value: "fecha" },
    { text: "Cantidad de productos traspasados", value: "cantidad" },
    { text: "Productos traspasados", value: "actions" },
  ];

  const transfersList = [
    {
      id: 47,
      user_name: "cstonebridge1a",
      remitente: "panda00001",
      destinatario: "cstonebridge1a",
      tipo: "compra",
      fecha: new Date().toLocaleDateString(),
      cantidad: "0",
    },
  ];
  return (
    <React.Fragment>
      <Row>
        <div className="fs-6 my-3">
          Registros: <b>{transfersList.length}</b>
        </div>
      </Row>
      <Col>
        <Card>
          <CardBody>
            <div className="table-responsive table-card">
              <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
                <thead className="text-muted table-light">
                  <tr>
                    {headers.map((header) => (
                      <th key={header.value} scope="col">
                        {header.text}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {transfersList.length ? (
                    (transfersList || []).map((item, index) => (
                      <tr key={index}>
                        {headers.map((header) => (
                          <td
                            key={header.value + "-" + index}
                            style={{
                              width: header.value == "actions" ? "10%" : "auto",
                            }}
                          >
                            {header.value == "actions" ? (
                              <Link
                                className="btn btn-primary"
                                to={`/inventario-de-productos/transpaso/${item.user_name}`}
                              >
                                Ver listado
                              </Link>
                            ) : (
                              item[header.value]
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="100%" className="text-center">
                        No hay registros existentes
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ProductsInventory;
