import React from "react";

import { useParams, useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import InformationTable from "./InformationTable";
import ProductsTable from "./ProductsTable";

const UserTransfers = () => {
  const { username } = useParams();
  const navigate = useNavigate();

  document.title = `SIMS de ${username} | Infynit`;

  const fetchedUser = {
    id: 47,
    first_name: "Carole",
    last_name: "Stonebridge",
    user_name: "cstonebridge1a",
  };

  return (
    <div className="page-content">
      <Container fluid>
        <h1>Transpasos de {fetchedUser.user_name}</h1>
        <div className="d-flex">
          <div
            className="text-primary"
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
          >
            Regresar
          </div>
          <div className="mx-1">/</div>
          Ver productos transferidos
        </div>

        <InformationTable />
        <ProductsTable />
      </Container>
    </div>
  );
};

export default UserTransfers;
