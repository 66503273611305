import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Spinner,
} from "reactstrap";

//redux

import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import logoLight from "@images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { errorAlert, successAlert } from "../../helpers/alerts";
import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { sendRecoverPasswordReq } from '../../helpers/fakebackend_helper';

const SetNewPassword = (props) => {
  document.title = "New Password | Infynit";
  const [isLoading, setIsLoading] = useState(false);
  const [successC, setSuccessC] = React.useState(false)
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get('token');
  const email = searchParams.get('email');

  useEffect(() => {
    if (!token || !email) {
      errorAlert('No está autorizado para esta acción');
      navigate('/login');
    }
  }, [navigate, token, email])

  const setNewPassword = async (payload) => {
    setIsLoading(true);
    const formvalues = {
      token,
      email,
      password: payload.password,
      password_confirmation: payload.password_confirmation,
    };
    try {
      setIsLoading(true);
      let response = await sendRecoverPasswordReq(formvalues);
      setIsLoading(false);

      if (response && response.status) {
        successAlert('Recuperación exitosa', 'Cambio de contraseña realizado correctamente');
        setSuccessC(true);
        return true;
      }
      return false;
    } catch (err) {
      setIsLoading(false);
      errorAlert("Error", '<p>Ocurrio un error al recuperar la contraseña<br/><br/>Recuerde que dispone de 1 hora desde que recibe el correo para realizar este cambio</p>');
      return false;
    }
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email,
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Por favor ingrese su numero"),
      password: Yup.string().required("Por favor ingrese su nueva contraseña"),
      password_confirmation: Yup.string().required("Por favor reingrese su nueva contraseña"),
    }),
    onSubmit: (values) => {
      setNewPassword(values);
    },
  });
  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="40" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">

                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">¿Olvidaste tu contraseña?</h5>

                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#0ab39c"
                      className="avatar-xl"
                      style={{ width: "120px", height: "120px" }}
                    ></lord-icon>
                  </div>
                  {!successC
                    ? <>
                      <Alert
                        className="border-0 alert-warning text-center mb-2 mx-2"
                        role="alert"
                      >
                        LLena el siguiente formulario para recuperar tu contraseña!
                      </Alert>

                      <div className="p-2">
                        <Form
                          autoComplete="false"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                          className="needs-validation"
                          action="#"
                        >
                          <div className="mb-4">
                            <Label className="form-label">Correo</Label>
                            <Input
                              disabled
                              name="email"
                              className="form-control"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={validation.touched.email && validation.errors.email}
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.email}</div>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">Nueva Contraseña</Label>
                            <Input
                              name="password"
                              className="form-control"
                              placeholder="Ingresa nueva contraseña"
                              type="password"
                              autoComplete="false"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={validation.touched.password && validation.errors.password}
                            />
                            {validation.touched.password &&
                              validation.errors.password ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.password}</div>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">Confirmacion Contraseña</Label>
                            <Input
                              name="password_confirmation"
                              className="form-control"
                              placeholder="Re-ingresa nueva contraseña"
                              type="password"
                              autoComplete="false"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password_confirmation || ""}
                              invalid={
                                validation.touched.password_confirmation &&
                                  validation.errors.password_confirmation
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password_confirmation &&
                              validation.errors.password_confirmation ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.password_confirmation}</div>
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="text-center mt-4">
                            <button className="btn btn-success w-100" type="submit" disabled={isLoading}>
                              {!isLoading && <span>Restablecer Contraseña</span>}
                              {isLoading && <Spinner size="sm"></Spinner>}
                            </button>
                          </div>
                        </Form>
                      </div>
                    </>
                    :
                    <div className="text-center">
                        <Alert
                        className="border-0 alert-warning text-center mb-2 mx-2"
                        role="alert"
                      >
                        El cambio se ha realizado con exito
                      </Alert>
                      <Link
                        to="/login"
                        className="fw-semibold text-primary text-decoration-underline"
                      >
                        Inicia sesión
                      </Link>
                    </div>
              }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

SetNewPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SetNewPassword);
