import React, { useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import TimeAirTabs from "./Components/TimeAirTabs";
import BalancesTabs from "./Components/BalancesTabs";
import SimsTabs from "./Components/SimsTabs";
import PortabilityTab from "./Components/PortabilityTab";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import TimeAirTab from "./Components/TimeAirTabs/TImeAirTab";

const Purchases = () => {
  document.title = "Historial de compras | Infynit";

  const reportRoutes = {
    "tiempo-aire": { text: "Tiempo aire", value: "1" },
    saldos: { text: "Saldos", value: "2" },
    sims: { text: "Sims", value: "3" },
    portabilidad: { text: "Portabilidad", value: "4" },
  };

  const tabsList = Object.entries(reportRoutes).map(([key, value]) => ({
    key,
    ...value,
  }));

  const params = useParams();
  const { tab } = params;

  const [pillTab, setPillTab] = useState(tabsList[0]);

  const replaceRoute = (tabPath) => {
    let fullPath = "/reportes";
    if (tabPath) fullPath = [fullPath, tabPath].join("/");
    window.history.replaceState(null, null, fullPath);
  };

  const togglePillTab = (selectedTab) => {
    if (pillTab.value !== selectedTab.value) {
      setPillTab(selectedTab);
      replaceRoute(selectedTab.key);
    }
  };

  useEffect(() => {
    replaceRoute(tab);
    if (reportRoutes[tab]) {
      setPillTab(reportRoutes[tab]);
    }
  }, [params]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h1>Historial de compras</h1>
          {/* <Card>
            <CardBody>
              <Nav pills className="nav-justified mb-1 align-items-center">
                {tabsList.map((tab) => (
                  <NavItem key={tab.value}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: pillTab.value === tab.value,
                      })}
                      onClick={() => {
                        togglePillTab(tab);
                      }}
                    >
                      {tab.text}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </CardBody>
          </Card> */}
          {/* <TabContent activeTab={pillTab.value} className="text-muted"> */}
          <React.Fragment>
            <TabPane tabId="1">
              <TimeAirTab />
            </TabPane>
          </React.Fragment>

          {/* <TabPane tabId="2">
              <BalancesTabs />
            </TabPane>
            <TabPane tabId="3">
              <SimsTabs />
            </TabPane>
            <TabPane tabId="4">
              <PortabilityTab />
            </TabPane> */}
          {/* </TabContent> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Purchases;
