import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import QuantityPicker from "@Components/UI/QuantityPicker";
import { useNavigate } from "react-router-dom";
import ResumeSection from "./ResumeSection";
import { useCartResume } from "../../../Components/Hooks/ShoppingCartHooks";

const ShoppingCart = () => {
  document.title = "Marketplace - Confirmar compra | Infynit";
  const navigate = useNavigate();

  const { cartProducts, onUpdateProductQty } = useCartResume();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h1>Marketplace - Confirmar compra</h1>
          <button className="btn btn-primary my-3" onClick={() => navigate(-1)}>
            Regresar al catálogo
          </button>
          <Row>
            <Col xs={12} md={8}>
              <Row>
                <h4 className="text-primary">Mi carrito</h4>
                <div className="dropdown-divider"></div>
              </Row>
              <Row className="align-items-center justify-content-between my-2">
                {cartProducts.map((product) => (
                  <Col xs={12} md={9} key={product._id}>
                    <Card className="border card-border-primary rounded-4">
                      <CardBody className="d-flex align-items-center">
                        <Col xs={2} md={1}>
                          <img src={product.mainPicture} class="w-100" />
                        </Col>
                        <Col xs={4} md={5} className="px-2">
                          <div className="fs-0-9 fw-medium my-1">
                            {product.brand} {product.model}
                          </div>
                          <h3 class="text-primary text-left w-100 mt-0 mb-1">
                            {`$${parseFloat(product.price).toFixed(2)}`}
                          </h3>
                        </Col>
                        <Col className="text-center">
                          <div className="fw-semibold">Cantidad</div>
                          <QuantityPicker
                            value={product.qty}
                            onUpdateValue={(value) =>
                              onUpdateProductQty(product._id, value)
                            }
                          />
                        </Col>
                        <Col xs={2} className="text-center">
                          <div className="fw-semibold text-primary">Total</div>
                          <div className="fw-semibold text-primary">
                            {`$${parseFloat(product.price).toFixed(2)}`}
                          </div>
                        </Col>
                        <Col className="col-auto">
                          <i className="la la-close m-2 cursor-pointer" />
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs={12} md={4}>
              <ResumeSection />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ShoppingCart;
