import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";

const ProductsInventory = () => {
  document.title = "Inventario de Productos | Infynit";

  const headers = [
    { text: "Cliente", value: "user_name" },
    { text: "Nombre", value: "first_name" },
    { text: "Acciones", value: "actions" },
  ];

  const productsInventory = [
    {
      id: 47,
      first_name: "Carole",
      last_name: "Stonebridge",
      user_name: "cstonebridge1a",
    },
  ];
  return (
    <React.Fragment>
      <Row>
        <div className="fs-6 my-3">
          Registros: <b>{productsInventory.length}</b>
        </div>
      </Row>
      <Col>
        <Card>
          <CardBody>
            <div className="table-responsive table-card">
              <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
                <thead className="text-muted table-light">
                  <tr>
                    {headers.map((header) => (
                      <th key={header.value} scope="col">
                        {header.text}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {productsInventory.length ? (
                    (productsInventory || []).map((item, index) => (
                      <tr key={index}>
                        {headers.map((header) => (
                          <td
                            key={header.value + "-" + index}
                            style={{
                              width: header.value == "actions" ? "10%" : "auto",
                            }}
                          >
                            {/* TODO: goto misma ruta /username */}
                            {header.value == "actions" ? (
                              <Link
                                className="btn btn-primary"
                                to={`/inventario-de-productos/inventario/${item.user_name}`}
                              >
                                Ver productos
                              </Link>
                            ) : (
                              item[header.value]
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="100%" className="text-center">
                        No hay registros existentes
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ProductsInventory;
