export const google = {
  API_KEY: "",
  CLIENT_ID: "",
  SECRET: "",
};
export const facebook = {
  APP_ID: "",
};
export const api = {
  //API_URL: "https://api.pandamovil.mx/api",
  API_URL: "https://api.infynit.mx/api",
};
